@import "../../styles/styles.scss";

.face_Container {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-image: url('../../Assets/images/bgdesktop.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .buttondiv {
    display: flex;
    justify-content: center;
    align-items: center;

    .face_found_text {
      position: absolute;
      text-align: center;
      bottom: 18%;
      color: #fff;

      .loading {
        width: 100px;
        position: relative;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto;
      }
    
      .loading .dots {
        width: 15px;
        height: 15px;
        background-color: $primary-white;
        border-radius: 50%;
        animation: anim 1.2s linear infinite;
      }
    
      .loading .dots:nth-child(1) {
        animation-delay: 0.4s;
      }
    
      .loading .dots:nth-child(2) {
        animation-delay: 0.8s;
      }
      .loading .dots:nth-child(3) {
        animation-delay: 1.2s;
      }
    
      @keyframes anim {
        from {
          background-color: rgb(136, 136, 207);
          transform: scale(0.7);
        }
    
        to {
          background-color: #fff;
          transform: scale(1);
        }
      }
    }
  }

  .button {
    position: fixed;
    bottom: 0;
    display: flex;
    background-color: $primary-yellow;
    color: $primary-black;
    max-width: 500px;
    width: 35%;
    margin: 3.5rem auto;
    padding: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;

    &:hover {
      background-color: $primary-yellow;
    }
  }

  .face_detect {
    position: absolute;
    width: 300px;
    height: 400px;
    background-color: $primary-black;
    transform: translate(-50%, -50%);
    // top: 50%;
    top: 40%;
    left: 50%;
    border-radius: 50%;

    .face_gif {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .face_anim_gif {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: none;
      z-index: 1;
      background-color: black;
    }

    .myVideo {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    .video,
    .video+canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      z-index: 0;
    }

    .video+canvas:first-of-type+canvas {
      display: none;
    }

    .face_canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

// Desktop Screens
@media screen and (min-width: 1025px) {
  .face_Container {
    background-image: url('../../Assets/images/infodesktop.png');
    overflow: hidden;

    .faceDetectArea{
      width: 30vw;
      height: 100vh;
      margin: 0% auto;
      background-image: url('../../Assets/images/bg_mobile2.jpg');
      background-repeat: no-repeat;
      background-size: cover;

      .gc{
        display: flex;
        justify-content: start;
        width: 100%;
        height: 100%;

        .buttondiv{
          .face_found_text{
            bottom: 5%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1080px) {
  .face_Container {
    .face_detect {
      top: 40%;
    }
  }
}

// Mobile Screens
@media screen and (max-width: 768px) {
  .face_Container {
    background-image: url('../../Assets/images/bg_mobile2.jpg');

    .button {
      margin: 1.5rem 0;
      width: 80%;
      font-size: 1.2rem;
    }

    .buttondiv{
      .face_found_text{
        bottom: 13%;
      }
    }

    .face_detect {
      // width: 40vw;
    }
  }
}
