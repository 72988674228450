@import "../../styles/styles.scss";

.videoEmotion {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    position: relative;

    .qA_container {
        position: absolute;
        z-index: 1;
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: linear-gradient(1deg, black, transparent);
        background-size: cover;

        .catname {
            color: $primary-white;
            text-transform: uppercase;

        }

        .question {
            text-align: left;
            padding: 0.5rem;
            font-size: 1.9rem;
            font-weight: 500;
            color: $primary-white;
            font-family: inherit;
            font-weight: 400;
            width: 85%;
            padding-left: 0;
            padding-right: 0;
            letter-spacing: 1px;
        }

        .answer_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .button {
                width: 85%;
                height: 10%;
                padding: 1.8rem;
                margin: 1rem 0;
                font-size: 1.4rem;
                font-weight: 600;
                background-color: $primary-yellow;
                color: $primary-black;
                border-radius: 0%;
                font-family: inherit;
                font-weight: 400;
            }
        }
    }

    .videoFaceApi {
        width: 80px;
        top: 8px;
        right: 8px;
        position: absolute;
        z-index: 0;
        box-shadow: 6px 6px 5px #333333ad;
        border-radius: 8px;
    }

    .riskReportBtn {
        position: absolute;
        font-size: 1.25rem;
        font-family: inherit;
        top: 81%;
        // left: 46px;
        right: 0%;
        color: $primary-white;
        cursor: pointer;
        font-weight: 300;

        // @media only screen and (min-width: 600px) {
        //     left: 33%;
        // }
    }

    .progressBar_container {
        position: absolute;
        z-index: 10;
        bottom: 2%;
        width: 100%;
        height: 3%;
        display: flex;
        justify-content: space-between;

        .progessBar {
            width: 32.5%;
            background-color: $primary-white;
        }
    }
}

.myVideo1 {
    object-fit: contain;
    // width: 100vw;
    height: 100vh;
    /* position: fixed; */
    top: 0;
    left: 0;
}

div[role="dialog"] {
    // background-color: transparent;
    // box-shadow: none;

    button {
        border-radius: 0%;
        background-color: $primary-yellow;
        color: $primary-black;
        font-family: "PGIM";
        font-size: 1.5rem;
        padding: 0 2rem;

        &:hover {
            background-color: $primary-yellow;
        }
    }
}

/* Package progress bar */

.wrapper {
    border: 3px solid blue;
}

.container {
    background-color: pink;
}

.barCompleted {
    background-color: lightblue;
    width: 80%;
}

.label {
    font-size: 20px;
    color: green;
}

.pb_container {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 2%;
}

.pb_container div[role='progressbar'] {
    width: 32%;
}

/* Responsiveness */
// --------------------------------------------------------------------------------
// Desktop Screen
@media screen and (min-width: 1025px) {
    .videoEmotionContainer {
        width: 100vw;
        height: 100vh;
        background-image: url('../../Assets/images/infodesktop.png');
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        .videoEmotion{
            width: 30vw;
            background-color: transparent;
            margin: 0% auto;

            .qA_container{
                width: 100%;
                .question{
                    text-align: left;
                    width: 85%;
                }
                .answer_container {
                    .button {
                        width: 85%;
                    }
                }
            }
            .videoFaceApi{
                width: 60px;
                height: 80px;
                object-fit: cover;
            }

            .pb_container{
                width: 100%;
            }
            .riskReportBtn{
                top: 90%;
                right: 2%;
             }
        }
    }
    .myVideo1{
        width: 100%;
        object-fit: fill;
    }
}

@media screen and (min-width: 1080px) {
    .videoEmotion {
        .qA_container {
            .question {
                padding: 1rem;
            }

            .answer_container {
                .button {
                    width: 80%;
                    padding: 1rem;
                    margin: 1rem 0;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .videoEmotion {
        .qA_container {
            width: 100%;

            .answer_container {
                .button {
                    padding: 1.5rem;
                    margin: 0.5rem 0 1rem 0;
                    font-size: 1.6rem;
                }
            }
        }

        .videoFaceApi {
            position: fixed;
        }

        .pb_container {
            position: fixed;
            // bottom: 11%;
            bottom: 4px;
        }
    }

    .myVideo1 {
        object-fit: cover;
        width: 100vw;
    }
}