@import "../../styles/styles.scss";

.start_Container {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-image: url('../../Assets/images/bgdesktop.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  font-family: inherit;

  .newLogo {
    padding: 4rem 3rem;
    height: 60px;
  }
}

.start {
  width: 100%;
  padding: 3rem;
  text-align: justify;


  .starttextPara {
    font-size: 1.2rem;
    color: white;
    line-height: 1.5;
    font-family: inherit;
    letter-spacing: 1px;
  }

  .startheading {
    color: white;
    line-height: 1.2;
    font-family: inherit;
    font-size: 4.5rem;
    letter-spacing: 2px;
  }

  .buttondiv {
    // display: flex;
    justify-content: center;
    align-items: center;
  }

  .button {
    // position: fixed;
    bottom: 0;
    display: flex;
    background-color: $primary-yellow;
    color: $primary-black;
    max-width: 500px;
    width: 40%;
    // margin: 3.5rem auto;
    font-size: 1.5rem;
    font-family: inherit;
    border-radius: 0%;

    &:hover {
      background-color: $primary-yellow;
    }
  }

}

/* Responsiveness */
// --------------------------------------------------------------------------------
// Desktop Screen
@media screen and (min-width: 1025px) {
  .start_Container {
    background-image: url('../../Assets/images/infodesktop.png');
    overflow: hidden;

    .startArea {
      width: 30vw;
      height: 100vh;
      margin: 0% auto;
      background-image: url('../../Assets/images/bg_mobile2.jpg');
      background-repeat: no-repeat;
      background-size: cover;

      .gc{
        display: flex;
        justify-content: start;
        width: 100%;
        height: 100%;

        .lngDropdown{
          top: 8%;
          margin: 0 1rem;
          right: 35%;
        }
      }

      .newLogo{
        padding: 0%;
        margin: 10% 0% 0% 10%;
      }

      .start {
        margin: 0% 10%;
        padding: 0%;

        .startheading {
          font-size: 3.5rem;
        }

        .starttextPara {
          font-size: 1.5rem;
          line-height: 1;
        }

        .button {
          font-size: 1.5rem;
          margin-top: 3rem;
          width: 60%;
        }
      }
    }
  }
}

// Mobile Screens
@media screen and (max-width: 768px) {
  .start_Container {
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/images/bg_mobile2.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .logoO {
      div {
        margin-bottom: 3rem;

        img {
          width: 60px;
          height: 60px;
        }

        p {
          font-size: 2.75rem;
        }
      }

    }

    .newLogo {
      padding: 4rem 1.2rem;
    }

    .start {
      padding: 0.5rem 1.5rem 0.7rem;
      bottom: 0%;
      // height: 60vh;

      .startheading {
        font-size: 2.2rem;
        color: white;
        line-height: 0.9;
        font-size: 5rem;
      }

      .starttextPara {
        margin: 1.2rem 0;
        line-height: 0.5;
        font-size: 2rem;
      }

      .button {
        margin: 3.5rem 0;
        width: 60%;
        font-size: 1.5rem;
      }
    }
  }
}
