@import "../../styles/styles.scss";

.about_Container {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-image: url('../../Assets/images/bgdesktop.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .logoO {
    div {
      margin: 3.5rem 0;
      margin-left: 2rem;
    }
  }

  .newLogo{
    padding: 4rem 3rem;
    height: 60px;
  }

   .face_gif{
    width: 75px;
    position: absolute;
    right: 12%;
    top: 5%;
    border-radius: 50%;
    display: none;
   }
}

.about {
  width: 100%;
  padding: 3rem;
  // font-family: "PGIM";

.about_heading{
  font-size: 2.9rem;
  color: $primary-white;
    font-family: inherit;
    line-height: 1;
}
  .about_textHeading {
    text-align: left;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .about_textPara {
    font-size: 1.2rem;
    color: $primary-white;
    margin: 0.5rem auto;
    font-family: inherit;
    letter-spacing: 0.8px;
  }

  .buttondiv {
    // display: flex;
    justify-content: center;
    align-items: center;
  }

  .button {
    // position: fixed;
    bottom: 0;
    display: flex;
    background-color: $primary-yellow;
    color: $primary-black;
    max-width: 500px;
    width: 40%;
    margin: 2.5rem 0;
    // padding: 0.5rem;
    font-size: 1.5rem;
    // font-weight: 600;
    // height: 4rem;
    font-family: inherit;
    border-radius: 0%;

    &:hover {
      background-color: $primary-yellow;
    }
  }
}

/* Responsiveness */
// --------------------------------------------------------------------------------
// Desktop Screens
@media screen and (min-width: 1025px) {
  .about_Container {
    background-image: url('../../Assets/images/infodesktop.png');
    overflow: hidden;

    .aboutArea {
      width: 30vw;
      height: 100vh;
      margin: 0% auto;
      background-image: url('../../Assets/images/bg_mobile.jpg');
      background-repeat: no-repeat;
      background-size: cover;

      .gc{
        display: flex;
        justify-content: start;
        width: 100%;
        height: 100%;
      }

      .newLogo{
        padding: 0%;
        margin: 10% 0% 0% 10%;
      }

      .about {
        margin: 0% 10%;
        padding: 0%;

        .about_heading {
          font-size: 2.5rem;
        }

        .about_textPara {
          font-size: 1.2rem;
          line-height: 1;
          margin: 1.5rem 0;
        }

        .button {
          font-size: 1.5rem;
          margin-top: 3rem;
          width: 60%;
        }
      }
    }
  }
}

// Mobile Screens
@media screen and (max-width: 768px) {
  .about_Container {
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/images/bg_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .logoO{
      div{
        margin-bottom: 3rem;
        img{
          width: 60px;
          height: 60px;
        }
        p{
          font-size: 2.75rem;
        }
      }
     }
     .newLogo{
      padding: 2.5rem 1.6rem 3rem;
    }
    .about {
      padding: 0 2rem 0.7rem;
      bottom: 0%;
      // height: 60vh;

      .about_textHeading {
        margin-bottom: 0.5rem;
        font-size: 1.8rem;
      }

      .about_textPara {
        line-height: 1;
        font-size: 1.6rem;
        font-weight: 100;
        margin: 0.95rem auto;

        &:first-of-type{
          margin: 0rem auto;
        }
        &:last-of-type{
          margin-bottom: 0rem;
        }
      }
     
      

      .button {
        margin: 1.8rem 0;
        width: 50%;
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 389px) {
  .about_Container{
    .newLogo{
      padding: 1.5rem 1.6rem 3rem;
    }
    .about{
      .about_textPara{
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: 1080px) {
  .about_Container {
    .about {
      .about_textPara {
        font-size: 1.4rem;
      }
      .button {
        font-size: 1.8rem;
      }
    }
  }
}