.chartparent{
    display:flex;
    justify-content: center;
    align-items:center;
    position: absolute;
    top: 0;
    width: 100%;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    // background-image: url('../../Assets/images/bgdesktop.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    // color: white;
    // background-color: black;
}
// #chartdiv{
//     margin-top: 2rem;
// }
 
.chartdiv {
    width: 100%;
    height: 400px;
    // div {
    //   div {
    //     div {
    //       canvas {
    //         left: -67px !important;
    //         width: 550px !important;
    //         height: 700px !important;
    //       }
    //     }
    //   }
    // }
   

  }

  .chartdiv div{
    margin-top:-10px;
  }

.Demo_chartdiv__UUZWB {
  background-color: red;
  div {
    background-color: red;
    div {
      background-color: red;
      div {
        background-color: red;
        canvas {
          left: -67px !important;
          width: 550px !important;
          height: 700px !important;
        }
      }
    }
  }
}

#chartdiv>div>div>div>canvas {
  left: -67px !important;
  width: 550px !important;
  height: 700px !important;
}