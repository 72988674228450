.dialog {
  white-space: pre-line
}

.MuiDialog-container {
  opacity: 1;
  animation: fade 2s linear;
}

@keyframes fade {

  0%,
  100% {
    opacity: 0
  }

  50% {
    opacity: 1
  }
}

.faceNotFoundDialog {
  div[role="dialog"] {
    padding: 3rem 0;
    color: rgb(24, 24, 24);

    .title {
      text-align: center;
      font-family: inherit;
      font-weight: 700;
      letter-spacing: 0;
      font-size: 1.2rem;
      padding: 0 16px 24px;
      line-height: 1;
    }

    .dialogContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: rgb(24, 24, 24);
      .icon {
        width: 60px;
        height: 60px;
      }

      .text1 {
        margin-bottom: 0;
      }

      .text2 {
        margin: 0.4rem auto 0;
      }
    }
    .action{
      justify-content: center;
      padding: 0;
      margin: 1rem auto 0;

      .actionBtn{
        line-height: 1.5;
        text-transform: capitalize;
        font-size: 1.3rem;
      }
    }
  }
}