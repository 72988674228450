@import "../../styles/styles.scss";


.test {
  white-space: pre-line !important;
}

.graph_container {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-image: url("../../Assets/images/infodesktop.png");
  background-repeat: no-repeat;
  background-size: cover;
  letter-spacing: 0.8px;
  color: $primary-white;

  .graph {
    width: 100vw;
    // height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    // font-family: "PGIM";

    .logoO {
      align-self: flex-end;
      margin: 0 4rem 0 0;

      div {
        margin: 1.5rem 0;
      }
    }

    .newLogo {
      align-self: flex-start;
      padding: 2rem 2rem;
      width: 220px;
      height: 65px;
      // position: relative;
      // top: -1%;
    }

    .newSvg {
      // position: absolute;
      // right: 5%;
      display: inline-flex;
      align-self: flex-end;
      padding-right: 5%;
      // top: 4%;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      z-index: 100;

      &:hover {
        transform: scale(0.96);
      }
    }

    .headertextdiv {
      // position: relative;
      // top: -15%;
    }

    .headertextdiv {
      .heading {
        font-weight: 500;
        //margin-bottom: 1rem;
        color: #c2d3ff;
        font-size: 2.5rem;
        // text-align: justify;
        font-family: inherit;

        &::before {
          // content: "";
          // border-top: 1px dashed $primary-white;
          // display: block;
        }
      }
    }

    .graph_textPara {
      font-size: 0.8rem;
      color: white;
      text-align: left;
      font-family: inherit;
      font-weight: 100;
      letter-spacing: 0.4px;
      line-height: 1.05;
    }

    .buttondiv {

      .reportTextContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: $primary-white;
        position: relative;
        text-align: left;
        padding: 0 16px;

        .reportTextTop {
          position: relative;
          display: flex;
          justify-content: center;
          // background-image: url("../../Assets/images/bg-top.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: top center;
          border-radius: 20px;

          div {
            background-color: #8256dd;
            position: relative;
            top: 5px;
            padding: 12px 24px 24px 24px;
            border-radius: 20px;
            font-size: 1.6rem;
            letter-spacing: 0.8px;
            margin: 0 2px;
            box-shadow: 0px -6px 0px #5DC9F7;
          }
        }

        .seprator {
          position: absolute;
          transform: translate(-50%, 0%);
          bottom: -26px;
          left: 50%;
          background-image: url("../../Assets/images/and-rounded.png");
          background-repeat: no-repeat;
          background-size: cover;
          width: 35px;
          height: 35px;
          z-index: 200;
        }

        .reportTextBottom {
          position: relative;
          display: flex;
          justify-content: center;
          // background-image: url("../../Assets/images/bg-bottom2.png");
          background-repeat: no-repeat;
          background-size: cover;
          background-position: bottom center;
          border-radius: 20px;

          div {
            background-color: #8256dd;
            // border-radius: 30px;
            // padding: 5px;
            position: relative;
            bottom: 5px;
            margin: 0 2px;
            box-shadow: 0px 6px 0px #5DC9F7;

            padding: 20px 24px 12px 24px;
            border-radius: 20px;
            font-size: 1.6rem;

            p {
              font-size: 1.3rem;
              margin: 0;
            }

            hr {
              border-color: #AA83F8;
              border-style: dashed;
            }
          }
        }
      }

      .graph_disclaimer {
        margin-left: 1rem;
        font-family: inherit;
        color: $primary-white;
        text-align: left;
      }
    }

    .graph_result,
    .graph_result_next {
      color: #fff;
      text-align: left;
      font-size: 1.8rem;
      font-weight: 500;
      font-family: inherit;
      letter-spacing: 1px;
      line-height: 1.25;

      span {
        text-transform: uppercase;
      }
    }

    .button {
      // position: fixed;
      // bottom: 0;
      display: flex;
      background-color: $primary-yellow;
      color: $primary-black;
      max-width: 500px;
      width: 40%;
      margin: 1.5rem 1rem;
      padding: 0.5rem;
      font-weight: 200;
      border-radius: 0%;
      font-family: inherit;
    }

    .qrImageContainer {
      width: 150px;
      height: 150px;
      margin: 1rem auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .infoImageContainer {
      width: 40px;
      position: absolute;
      bottom: -2%;
      right: -1%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .buttongraph_textPara {}

    .demo_graph {
      width: 100vw;
      // height: 30vh;

      .chartdiv {
        height: 250px;
      }

      .chartparent {
        position: relative;

        .emotionmeter {
          position: absolute;
        }
      }

      canvas:nth-child(2) {
        display: none;
      }

      .legendList {
        // position: absolute;
        // bottom: 0;
        // left: 46%;
        display: flex;
        flex-direction: column;
        width: 200px;

        li {
          list-style: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .legendListStyleBefore {
            width: 10px;
            height: 10px;
            background-color: $primary-white;
          }

          .legendListText {
            margin-left: 0.8rem;
            font-size: 1.3rem;
            color: $primary-white;
          }
        }
      }

      .legendLinaers {
        // position: absolute;
        display: flex;
        justify-content: space-between;
        width: 80%;
        text-align: center;
        margin: -55px auto 0px auto;
        // bottom: -73%;
        // left: 10%;

        .legendLinaer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 0.7rem;

          .legendLinearBefore {
            width: 11.5px;
            height: 11.5px;
            background-color: $primary-white;
          }

          .legendLinaerText {
            margin-left: 0.3rem;
            font-size: 1rem;
            font-weight: 100;
            color: $primary-white;
            letter-spacing: 1px;
          }
        }
      }
    }

    .chartdivfirst {
      margin-top: 37px !important;
    }
  }
}

/* Responsiveness */
// --------------------------------------------------------------------------------

// Ipad
@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .graph_container {
    .graph {
      height: 100vh;
      justify-content: center;

      .headerline {
        display: flex;
        justify-content: space-between;
        width: 60vw;

        .newSvg {
          align-self: unset;
        }
      }

      .headertextdiv {
        margin-top: 1.3rem;
      }

      .demo_graph {
        margin-top: -35px;

        .chartdiv {
          height: 300px;
        }

        .legendLinaers {
          width: 37%;
        }

        .chartparent {
          .emotionmeter {
            left: 45% !important;
            top: 66%;
            line-height: 0;
            letter-spacing: 1px;
          }
        }
      }

      .buttondiv {
        position: relative;
        width: 55vw;

        .reportTextContainer {

          // width: 60vw;
          .reportTextBottom {
            margin-top: 4.5%;
          }

          .seprator {
            bottom: -30px;
            width: 45px;
            height: 45px;
          }
        }

        .button {
          padding: 0;
          font-size: 1.3rem;
        }

        .infoImageContainer {
          right: -42%;
          bottom: 88px;
        }

        .graph_disclaimer {}
      }
    }
  }
}


// Desktop Screen

@media screen and (min-width: 1025px) {

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #7861B7;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #512DAC
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #34177C;
  }

  .graph_container {
    .graph {
      background-image: url("../../Assets/images/infodesktop.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 30vw;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;

      .headerline {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        .newLogo {
          padding: 0.5rem 0;
          width: 120px;
          height: 36px;
        }

        .newSvg {
          position: relative;
          bottom: 15px;
          margin-left: 5%;
          left: 17%;
        }
      }

      .demo_graph {
        width: 100%;

        .chartdiv {
          height: 230px;
        }

        .chartparent {
          position: relative;

          .emotionmeter {
            position: absolute;
            top: 60%;
            left: 40%;
            color: white;

            p {
              line-height: 1px;
              font-family: inherit;
              letter-spacing: 1px;

            }
          }
        }
      }

      .headertextdiv {
        margin-top: 1.2rem;

        .heading {
          font-size: 2.5rem;
        }
      }

      .buttondiv {
        position: relative;

        .reportTextContainer {
          .reportTextTop {
            div {
              font-size: 1.35rem;
              line-height: 1.1;
              padding: 18px 24px 12px 24px;
            }
          }

          .seprator {
            width: 32px;
            height: 32px;
          }

          .reportTextBottom {
            margin-top: 4.5%;

            div {
              font-size: 1.35rem;
              line-height: 1.1;

              p {
                font-size: 0.9rem;
              }

              hr {
                margin: 6px 0;
              }
            }
          }
        }

        .button {
          margin: 1.5rem 1rem;
          width: 55%;
          font-size: 1.3rem;
          padding: 0;
        }

        .graph_result_next {
          font-size: 1.65rem;
        }
      }

      .graph_disclaimer {
        font-size: 0.9rem;
        text-align: left;
        color: $primary-white;
        font-family: inherit;
        line-height: 1;
        margin-bottom: 0.8rem;
      }

      .infoImageContainer {
        bottom: 15%;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .graph_container {
    .graph {
      .demo_graph {
        .legendLinaers {
          width: 60%;
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .graph_container {
    .graph {
      justify-content: space-evenly;

      .headerline {

        .newLogo {
          width: 160px;
          height: 50px;
        }

        .newSvg {
          bottom: 20px;
          left: 13%;
        }
      }

      .demo_graph {

        .chartdiv {
          height: 300px;
        }

        .chartparent {
          .emotionmeter {
            p {
              font-size: 1.25rem;
            }
          }
        }

        .legendLinaers {
          .legendLinaer {
            .legendLinearBefore {
              width: 15px;
              height: 15px;
            }

            .legendLinaerText {
              font-size: 1.1rem;
            }
          }
        }
      }

      .headertextdiv {
        margin-top: 0;

        .heading {
          font-size: 3rem;
        }
      }

      .buttondiv {

        .reportTextContainer {
          .reportTextTop {
            div {
              font-size: 1.65rem;
            }
          }
          .reportTextBottom{
            margin-top: 5%;
            div{
              font-size: 1.65rem;
              hr{
                margin: 10px 0;
              }
              p{
                font-size: 1.2rem;
              }
            }
          }
        }
      }
      .infoImageContainer {
        bottom: 10%;
      }
    }
  }
}

@media screen and (min-width: 1580px) {
  .graph_container {
    .graph {
      .demo_graph {
        .chartparent {
          .emotionmeter {
            // left: 40%;
            // top: 56%;
          }
        }

        .legendLinaers {
          width: 55%;
        }
      }
    }
  }
}

// @media screen and (min-width: 1080px) {
//   .graph_container {
//     .graph {
//       .heading {
//         font-size: 1.5rem;
//       }

//       .graph_textPara {
//         font-size: 0.9rem;
//       }
//       .graph_disclaimer{
//         font-size: 0.9rem;
//         text-align: left;
//         color: $primary-white;
//         font-family: inherit;
//       }

//       .buttondiv {
//         padding: 0.5rem;

//         .button {
//           margin: 1rem 0 0.5rem;
//           width: 80%;
//           font-size: 1.3rem;
//           padding: 0;
//         }

//         .graph_result_next {
//           font-size: 1.65rem;
//         }
//       }

//       .demo_graph {

//         .chartdiv {
//           height: 350px;
//         }

//         .legendList {

//           li {
//             .legendListText {
//               font-size: 1rem;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// Mobile Screens
@media screen and (max-width: 768px) {
  .graph_container {
    position: relative;
    min-width: 100vw;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background-image: url("../../Assets/images/infomobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;

    .graph {

      // height: 60vh;
      .headertextdiv {
        // position: relative;
        // top: -3%;
        padding: 0 1rem;
        margin-top: 1rem;

        .heading {
          font-size: 2.3rem;
        }
      }

      .headerline {
        display: inline-block;
        width: 100%;
        flex-direction: column;
        margin-top: 3px;

        .newLogo {
          float: left;
        }

        .newSvg {
          padding: 16px 16px 0px 0px;
          float: right;
        }
      }

      .logoO {
        margin: 0 2rem 0 0;

        div {
          margin: 1rem 0 3rem;

          img {
            width: 50px;
            height: 50px;
          }

          p {
            font-size: 2rem;
          }
        }
      }

      .newLogo {
        padding: 0.8rem 1.5rem 0;
        width: 100px;
        height: 30px;
      }

      .demo_graph {
        // position: relative;
        // top: -28%;

        .chartparent {
          position: relative;

          .emotionmeter {
            position: absolute;
            top: 64%;
            left: 40%;
            color: white;

            p {
              line-height: 1px;
              font-family: inherit;
              letter-spacing: 1px;

            }
          }
        }

        .legendLinaers {
          width: 60%;
          margin: -60px auto 0px auto;
        }

        .legendList {
          // bottom: -68%;
          // left: 35%;

          li {
            .legendListStyleBefore {
              width: 7px;
              height: 7px;
            }

            .legendListText {
              font-size: 0.7rem;
              font-weight: 100;
            }
          }
        }
      }

      .graph_disclaimer {
        line-height: 1.1;
        font-size: 0.65rem;
        color: #fefefe;
        margin: 0.5rem 0;
        // position: absolute;
        text-align: left;
        // bottom: 10px;
        font-family: inherit;
      }

      .infoImageContainer {
        bottom: 30px;
        right: -5px;
      }

      .graph_textPara {
        line-height: 1.1;
        font-size: 1.4rem;
        margin: 0.9rem 0;
      }

      .buttondiv {
        margin-top: 20px;
        position: relative;
        // top: -7%;

        .reportTextContainer {
          .reportTextTop {
            height: 40%;

            div {
              top: 5px;
              padding: 12px 24px 24px 24px;
              border-radius: 20px;
              font-size: 1.6rem;
              letter-spacing: 0.8px;
              margin: 0 2px;
            }
          }

          .reportTextBottom {
            // height: 50%;
            margin-top: 6%;
            padding: 0 2px;

            div {
              // width: 86vw;
              padding: 20px 24px 12px 24px;
              border-radius: 20px;
              font-size: 1.6rem;

              p {
                font-size: 1.3rem;
                margin: 0;
              }

              hr {
                margin: 8px 0;
              }
            }
          }
        }

        .button {
          border-radius: 0%;
          font-family: inherit;
          padding: 0%;
          font-size: 1.3rem;
          margin: 1.5rem 1rem 1rem;
          width: 60%;
        }

        .graph_result_next {
          line-height: 1;
          font-size: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .graph_container {
    .graph {
      .demo_graph {

        .chartparent {
          .emotionmeter {
            position: absolute;
            top: 60%;
            left: 38%;
          }
        }
      }
    }
  }
}