.load_txt {
  /* position: absolute;
  transform: translate(50%, 50%);
  bottom: 25%;
  left: 10%; */
  color: rgb(255, 255, 255);
  font-family: inherit;
  font-size: 1.5rem;
  letter-spacing: 2px;
}
.load_background {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-image: url("../../Assets/images/bgdesktop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
#floatingCirclesG {
  position: relative;
  width: 352px;
  height: 352px;
  margin: auto;
  transform: scale(0.6);
  -o-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
}

.f_circleG {
  position: absolute;
  background-color: rgb(255, 255, 255);
  height: 63px;
  width: 63px;
  border-radius: 33px;
  -o-border-radius: 33px;
  -ms-border-radius: 33px;
  -webkit-border-radius: 33px;
  -moz-border-radius: 33px;
  animation-name: f_fadeG;
  -o-animation-name: f_fadeG;
  -ms-animation-name: f_fadeG;
  -webkit-animation-name: f_fadeG;
  -moz-animation-name: f_fadeG;
  animation-duration: 2.44s;
  -o-animation-duration: 2.44s;
  -ms-animation-duration: 2.44s;
  -webkit-animation-duration: 2.44s;
  -moz-animation-duration: 2.44s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}

#frotateG_01 {
  left: 0;
  top: 143px;
  animation-delay: 0.92s;
  -o-animation-delay: 0.92s;
  -ms-animation-delay: 0.92s;
  -webkit-animation-delay: 0.92s;
  -moz-animation-delay: 0.92s;
}

#frotateG_02 {
  left: 41px;
  top: 41px;
  animation-delay: 1.22s;
  -o-animation-delay: 1.22s;
  -ms-animation-delay: 1.22s;
  -webkit-animation-delay: 1.22s;
  -moz-animation-delay: 1.22s;
}

#frotateG_03 {
  left: 143px;
  top: 0;
  animation-delay: 1.53s;
  -o-animation-delay: 1.53s;
  -ms-animation-delay: 1.53s;
  -webkit-animation-delay: 1.53s;
  -moz-animation-delay: 1.53s;
}

#frotateG_04 {
  right: 41px;
  top: 41px;
  animation-delay: 1.83s;
  -o-animation-delay: 1.83s;
  -ms-animation-delay: 1.83s;
  -webkit-animation-delay: 1.83s;
  -moz-animation-delay: 1.83s;
}

#frotateG_05 {
  right: 0;
  top: 143px;
  animation-delay: 2.14s;
  -o-animation-delay: 2.14s;
  -ms-animation-delay: 2.14s;
  -webkit-animation-delay: 2.14s;
  -moz-animation-delay: 2.14s;
}

#frotateG_06 {
  right: 41px;
  bottom: 41px;
  animation-delay: 2.44s;
  -o-animation-delay: 2.44s;
  -ms-animation-delay: 2.44s;
  -webkit-animation-delay: 2.44s;
  -moz-animation-delay: 2.44s;
}

#frotateG_07 {
  left: 143px;
  bottom: 0;
  animation-delay: 2.75s;
  -o-animation-delay: 2.75s;
  -ms-animation-delay: 2.75s;
  -webkit-animation-delay: 2.75s;
  -moz-animation-delay: 2.75s;
}

#frotateG_08 {
  left: 41px;
  bottom: 41px;
  animation-delay: 3.06s;
  -o-animation-delay: 3.06s;
  -ms-animation-delay: 3.06s;
  -webkit-animation-delay: 3.06s;
  -moz-animation-delay: 3.06s;
}

@keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}

@-o-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}

@-ms-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}

@-webkit-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}

@-moz-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}

/* New Loader */
.loader_container {
  /* width: 200px; */
  height: 300px;
  /* position: relative; */
  /* position: absolute;
  left: 50%;
  top: 35%; */
}
.circle {
  height: 200px;
  position: absolute;
  animation: spin 3s linear infinite;
}
.loader_container .circle div {
  width: 30px;
  height: 30px;
  background-color: #eae5f3;
  border-radius: 50%;
  animation: dots 3s linear infinite;
}
.loader_container .circle:nth-child(1) div {
  animation-delay: 0.25s;
}
.loader_container .circle:nth-child(2) div {
  animation-delay: 0.5s;
}
.loader_container .circle:nth-child(3) div {
  animation-delay: 0.75s;
}
.loader_container .circle:nth-child(4) div {
  animation-delay: 1s;
}
.loader_container .circle:nth-child(5) div {
  animation-delay: 1.25s;
}
.loader_container .circle:nth-child(6) div {
  animation-delay: 1.5s;
}
.loader_container .circle:nth-child(7) div {
  animation-delay: 1.75s;
}
.loader_container .circle:nth-child(8) div {
  animation-delay: 2s;
}
.loader_container .circle:nth-child(9) div {
  animation-delay: 2.25s;
}
.loader_container .circle:nth-child(10) div {
  animation-delay: 2.5s;
}
.loader_container .circle:nth-child(11) div {
  animation-delay: 2.75s;
}
.loader_container .circle:nth-child(12) div {
  animation-delay: 3s;
}
.loader_container .circle:nth-child(1) {
  animation-delay: 0.25s;
}
.loader_container .circle:nth-child(2) {
  animation-delay: 0.5s;
}
.loader_container .circle:nth-child(3) {
  animation-delay: 0.75s;
}
.loader_container .circle:nth-child(4) {
  animation-delay: 1s;
}
.loader_container .circle:nth-child(5) {
  animation-delay: 1.25s;
}
.loader_container .circle:nth-child(6) {
  animation-delay: 1.5s;
}
.loader_container .circle:nth-child(7) {
  animation-delay: 1.75s;
}
.loader_container .circle:nth-child(8) {
  animation-delay: 2s;
}
.loader_container .circle:nth-child(9) {
  animation-delay: 2.25s;
}
.loader_container .circle:nth-child(10) {
  animation-delay: 2.5s;
}
.loader_container .circle:nth-child(11) {
  animation-delay: 2.75s;
}
.loader_container .circle:nth-child(12) {
  animation-delay: 3s;
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes dots {
  from {
    background-color: #c19df1;
    transform: scale(0.4);
  }
  to {
    background-color: #eae5f3;
  }
}

/* Responsiveness */
@media screen and (min-width: 1080px) and (max-width:1550px) {
	.load_txt {
		left: 34%;
	  }
}
@media screen and (min-width: 1551px) {
	.load_txt {
		left: 36%;
	  }
}

@media screen and (max-width: 768px) {
  .load_background {
    background-image: url("../../Assets/images/bg_mobile2.jpg");
  }
  .loader_container {
    top:30%
  }
  .load_txt {
	  left: -3%;
    bottom: 32%;
  }
}