.message_dialog{
    font-family: inherit;
    div[role="dialog"] {
        // background-color: transparent;
        // height: 80vh;
        // width: 96%;
        // max-width: 90vw;
    }
    
}
.dialog{
    white-space:pre-line
}

// .animation{
//     transition: transform 500ms ease-in;
// }

.MuiDialog-container {
    opacity: 1;
    animation: fade 2s linear;
}
@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}